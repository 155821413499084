/*export function makeResponsive(board) {
    let resizeFunction = function() {
        board.resizeContainer(board.containerObj.clientWidth,
                              board.containerObj.clientHeight,
                              true);
        board.fullUpdate();
    };
    window.addEventListener("resize", resizeFunction);
}*/
export function placeLogo(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/10;
  let im =board.create('image', ['/assets/logo.png', [boundingBox[2]-size, boundingBox[1]-size],[size,size]], imageAttributes);
  return im;
}
////////////////////////////////////////
export function makeResponsive(board) {
    let resizeFunction = function() {
        const bb = board.attr.boundingbox;
        board.resizeContainer(board.containerObj.clientWidth,
                              board.containerObj.clientHeight,
                              true,
                              true);
        board.setBoundingBox(bb);
        board.fullUpdate();
    };
    window.addEventListener("resize", resizeFunction);
}
////////////////////////////////////////PLACE ANGLE/////////////////////////////
export function placeAngle(board, Pt1, Pt2, Pt3, width, cols){
  const lm = {
    radius:width,
    strokeWidth:0,
    strokeColor:cols,
    fillColor:cols,
    highlight:false,
    radiusPoint:{visible:false},
    label:{color:'black', anchorX:'middle', anchorY:'middle', fontSize:function(){return 18*board.canvasHeight/800},
    cssStyle:'fontFamily:Oswald;'}
    }
  let le=board.create('angle', [Pt1, Pt2, Pt3], lm);
  le.setAttribute({center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
  return le;
}
///////////////////////////////////////////LINE////////////////////////////////
export function placeLine(board, Pt1, Pt2, width, cols){
  const lm ={
  fixed:true,
  strokeColor:cols,
  highlight:false,
  strokeWidth: () => Math.round(width*board.canvasWidth /800.)};
  let le=board.create('segment', [[Pt1[0], Pt1[1]],[Pt2[0], Pt2[1]]] , lm);
  return le;
}
/////////////////////////////////////////////////////////////////////////////
export function placeDash(board, Pt1, Pt2, width, cols){
  const lm ={
  fixed:true,
  dash:1,
  strokeColor:cols,
  highlight:false,
  strokeWidth: () => Math.round(width*board.canvasWidth /800.)};
  let le=board.create('segment', [[Pt1[0], Pt1[1]],[Pt2[0], Pt2[1]]] , lm);
  return le;
}
///////////////////////////////////////////CIRCLE/////////////////////////
export function placeCircle(board, Pt1, Pt2, width, cols){
  const lm ={
  fixed:true,
  strokeColor:cols,
  fillColor:'white',
  fillOpacity:1,
  highlight:false,
  strokeWidth: () => Math.round(width*board.canvasWidth /800.)};
  let le=board.create('circle', [[Pt1[0], Pt1[1]],[Pt2[0], Pt2[1]]] , lm);
  return le;
}
///// Arc
export function placeArc(board, Pt1, Pt2, Pt3, width, cols){
  const lm ={
  fixed:true,
  dash:1,
  strokeColor:cols,
  fillOpacity:1,
  highlight:false,
  strokeWidth: () => Math.round(width*board.canvasWidth /800.)};
  let le=board.create('arc', [[Pt1[0], Pt1[1]],[Pt2[0], Pt2[1]],[Pt3[0], Pt3[1]]], lm);
  le.setAttribute({center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
  return le;
}
//////////////////////////////////////TEXT/////////////////////////////
export function placeText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  //display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeLeftText(board, positionX, positionY, message){
  const m ={
  anchorX: 'left',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeRightText(board, positionX, positionY, message){
  const m ={
  anchorX: 'right',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeBoldText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;fontWeight:bold',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
/////////////////////////// Draggable Point/////////////////////////////////////
export function dragMe(board, positionX, positionY){
   const pointAttributes ={
    name:'Drag me',
    size:3,
    strokeColor:'black',
    fillColor:'white',
    fixed:false,
    label:{color:'blue',
           offset:[0,20],
           cssStyle:'fontFamily:Oswald',
           fontSize:()=>Math.round(16*board.canvasWidth/800.)
          }
   };
   let pt = board.create('point',[positionX, positionY], pointAttributes);
   return [pt];
   }
//////////////////////////HiddenPoint////////////////////////////////////////
export function hiddenPt(board, positionX, positionY){
   const pointAttributes ={
    name:'Drag me',
    size:3,
    strokeColor:'black',
    fillColor:'white',
    fixed:false,
    visible:false,
    label:{color:'blue',
           offset:[0,20],
           cssStyle:'fontFamily:Oswald',
           fontSize:()=>Math.round(16*board.canvasWidth/800.)
          }
   };
   let pt = board.create('point',[positionX, positionY], pointAttributes);
   return pt;
   }
///////////////////////////FixedPoint/////////////////////////////////////////
export function fixedPt(board, positionX, positionY, size, name, cols1, cols2){
   const pointAttributes ={
    name:name,
    size: ()=>Math.round(size*board.canvasWidth/800.),
    strokeWidth:()=>Math.ceil(2*board.canvasWidth/800.),
    fillColor:cols1,
    strokeColor:cols2,
    highlight:false,
    fixed:true,
    label:{color:'blue',
           offset:[0,20],
           cssStyle:'fontFamily:Oswald',
           fontSize:()=>Math.round(16*board.canvasWidth/800.)
          }
   };
   let pt = board.create('point',[positionX, positionY], pointAttributes);
   return pt;
   }
/////////////////////////////////////////////////////////////////////////////
export function placeArrow(board, pt1, pt2, width, cols){
     const recAttributes = {
       strokeWidth: () => Math.round(width*board.canvasWidth/800.),
       strokeColor:cols,
       highlight:false,
       fillOpacity:1.0}
     let m =board.create('arrow', [[pt1[0], pt1[1]],
                                    [pt2[0], pt2[1]]], recAttributes);
     return m;
   }
///////////////////////////////////////////////////////////////////////////
export function placePoint(board, positionX, positionY, size, cols1, cols2){
     const ptA = {
       name:'',
       size: ()=>Math.round(size*board.canvasWidth/800.),
       strokeWidth:()=>Math.ceil(2*board.canvasWidth/800.),
       fillColor:cols1,
       strokeColor:cols2,
       highlight:false};
     let ptM =board.create('point', [positionX, positionY], ptA);
     return ptM;
   }
//////////////////////////////Gravity ////////////////////////////////////////
export function placeGlider(board, line, positionX, positionY, size, cols1, cols2){
     const ptA = {
       name:'',
       size: ()=>Math.round(size*board.canvasWidth/800.),
       strokeWidth:()=>Math.ceil(2*board.canvasWidth/800.),
       fillColor:cols1,
       strokeColor:cols2,
       highlight:false};
     let ptM =board.create('glider', [positionX, positionY, line], ptA);
     return ptM;
   }
//////////////////////////////////////////////////////////////////////////////////
 export function placeGravity(board, pt1, pt2, val)
 {
     const arrowAttributes = {
       fixed:true,
       highlight:false,
       strokeWidth: () => Math.round(3*board.canvasWidth/800.),
       strokeColor:'black',
       fillOpacity:1.0
     };
     const gAttributes={
       fixed:true,
       highlight:false,
       anchorX: 'left',
       anchorY:'middle',
       color:'black',
       CssStyle: 'font-weight: bold; fontFamily: Oswald;',
       fontSize: () => Math.round(24*board.canvasWidth /800.)
     };
     let gtext =board.create('text', [0.5*(pt1[0]+pt2[0])+Math.abs(pt1[1]-pt2[1])*0.25, 0.5*(pt1[1]+pt2[1])+Math.abs(pt1[0]-pt2[0])*0.25, val], gAttributes);
     let garrow=board.create('arrow', [
                                     [pt1[0], pt1[1]],
                                     [pt2[0], pt2[1]]
                                     ], arrowAttributes);

     return [garrow, gtext];
   }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function placeRuler(board, pt1, pt2, val)
   {
       const arrowAttributes = {
         fixed:true,
         highlight:false,
         strokeWidth: () => Math.round(3*board.canvasWidth/800.),
         strokeColor:'black',
         fillOpacity:1.0
       };
       const gAttributes={
         fixed:true,
         highlight:false,
         anchorX: 'middle',
         anchorY:'middle',
         color:'black',
         CssStyle: 'font-weight: bold; fontFamily: Oswald;',
         fontSize: () => Math.round(28*board.canvasWidth /800.)
       };
       let garrow=board.create('arrow', [[pt1[0], pt1[1]],[pt2[0], pt2[1]]], arrowAttributes);
       let gtext =board.create('text',[0.5*(1.00*pt1[0]+1.00*pt2[0])+Math.abs(1.00*pt1[1]-1.00*pt2[1])*0.25, 0.5*(1.00*pt1[1]+1.00*pt2[1])+Math.abs(1.00*pt1[0]-1.00*pt2[0])*0.25, val], gAttributes);
       return [garrow, gtext];
    }
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////Polygon////////////////////////////////////////
export function placeRec(board, pt1, pt2, pt3, pt4, cols){
  const recAttributes = {
    vertices:{visible:false, fixed:true},
    borders:{visible:false},
    fillColor:cols,
    fillOpacity:1.0}
  let m =board.create('polygon', [[pt1[0], pt1[1]],
                                 [pt2[0], pt2[1]],
                                 [pt3[0], pt3[1]],
                                 [pt4[0], pt4[1]]], recAttributes);
  return [m];
}
//////////////////////////////////////////////Circle/////////////////////////////////////
export function placeSlider(board, positionX, positionY, limit1, limit2, length, name)
{
  const sliderAttributes={
    baseline:{strokeWidth:7*board.canvasHeight/800, strokeColor:'grey', highlight:false},
    highline:{strokeWidth:7*board.canvasHeight/800, strokeColor:'black', highlight:false},
    name:name,
    highlight:false,
    size:()=>8*board.canvasHeight/800,
    face:'square',
    fillColor:'grey',
    strokeColor:'black',
    withTicks:false,
    label:{fontSize:()=> Math.round(18*board.canvasWidth/800.),
    cssStyle:'fontFamily:Oswald;', highlight:false}
  }
   let m = board.create('slider',[[positionX, positionY],[positionX+length, positionY],[limit1, 0.5*(limit1+limit2), limit2]], sliderAttributes);    //
   return m;
}
export function placeSliderSwitch(board, positionX, positionY, limit1, limit2, length, name)
{
  const sliderAttributes={
    baseline:{strokeWidth:7*board.canvasHeight/800,strokeColor:'grey'},
    highline:{strokeWidth:7*board.canvasHeight/800,strokeColor:'black'},
    name:name,
    size:()=>8*board.canvasHeight/800,
    highlight:false,
    face:'square',
    fillColor:'#FF0000',
    strokeColor:'black',
    snapWidth:1,
    withTicks:false,
    label:{anchorX:'left',fontSize:()=> Math.round(24*board.canvasWidth/800.),
    cssStyle:'fontFamily:Oswald;'}
  }
   let m = board.create('slider',[[positionX, positionY],[positionX+length, positionY],[limit1, 0.5*(limit1+limit2), limit2]], sliderAttributes);    //
   return m;
}

export function placeImage(board, imValue, positionX, positionY, size, rotation){
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:rotation,
  };
   let im =board.create('image', [imValue, [positionX, positionY],[size,size]], imageAttributes);
   return im;
}

export function placeInput(board, positionX, positionY){
  const inputAttributes ={
    fixed: true,
    cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border: 1px solid black;border-radius:3.5px;',
    fontSize:function(){return Math.round(24*board.canvasWidth/800.)},
    fixed:true,
    parse:true
  }
   let inp =board.create('input', [positionX, positionY,'', ''], inputAttributes);
   return [inp];
}
export function hoverMe(board, element, message, Xoffset, Yoffset)
{
  element.setLabel(message);
  element.label.setAttribute({visible:false,
                              offset:[Xoffset, Yoffset],
                              CssStyle:'fontFamily:Oswald;color:red',
                              fontSize:function(){return Math.round(12*board.canvasWidth/800.)}});
  element.on('over', function () {this.label.setAttribute({visible:true});});
  element.on('out', function () {this.label.setAttribute({visible:false});});
}
export function placeLabel(board, element, message, Xoffset, Yoffset)
{
  element.setLabel(message);
  element.label.setAttribute({
                              offset:[Xoffset, Yoffset],
                              anchorX:'left',
                              anchorY:'middle',
                              CssStyle:'fontFamily:Oswald;',
                              fontSize:function(){return Math.round(12*board.canvasWidth/800.)}});
}
export function placeTitle(board, titleText, subTitleText, offset = [0, -1.0]) {
    const commonTextAttributes = {
        anchorX: 'middle',
        display: 'internal',
        highlight: false,
        fixed: true
    };
    const titleTextAttributes = {
        ...commonTextAttributes,
        anchorY: 'middle',
        CssStyle: 'font-weight: bold; fontFamily: Oswald;',
        highlight:false,
        fontSize: () => Math.round(32 * board.canvasWidth /800.)
    };
    const subTitleTextAttributes = {
        ...commonTextAttributes,
        anchorY: 'middle',
        CssStyle: 'fontFamily: Oswald;',
        highlight:false,
        fontSize: () => Math.round(24 * board.canvasWidth /800.)
    };
    const boundingBox = board.attr.boundingbox;

    let title = board.create(
        'text',
        [(boundingBox[0] + boundingBox[2]) / 2.0 + offset[0],
         boundingBox[1] + offset[1],
         titleText],
        titleTextAttributes
    );
    let subtitle = board.create(
        'text',
        [(boundingBox[0] + boundingBox[2]) / 2.0 + offset[0],
         boundingBox[1] + 2*offset[1],
         subTitleText],
        subTitleTextAttributes
    );
    return [title, subtitle];
}

export function clearInputFields(board) {
    let magicGraph = board.containerObj;
    let inputFields = magicGraph.querySelectorAll("input");
    for (let inputField of inputFields) {
        inputField.value = "";
    }
}

export function setInputFieldAnswerColor(inputObject, isAnswerCorrect) {
  // TODO: Tableau colors don't look great with blue background, find a
  // different color scheme that looks better.
  let colorizeElement = function (element, condition) {
    if (element) {
      if (condition) {
        // element.style.borderColor = '#59A14F';
        element.style.backgroundColor = 'green';
      } else {
        // element.style.borderColor = '#E15759';
        element.style.backgroundColor = 'red';
      }
    }
  }

  colorizeElement(inputObject.rendNodeInput, isAnswerCorrect);
  colorizeElement(inputObject.rendNode, isAnswerCorrect);
}

export function markInteractive(inputObject) {
  let board = inputObject.board;
  if (!board._xedliy) {
    board._xedliy = {};
  }

  if (!board._xedliy.interactive) {
    let magicGraph = board.containerObj;
    let svgs = magicGraph.querySelectorAll("svg");
    for (let svg of svgs) {
      svg.style.pointerEvents = "none";
    }
    board._xedliy.interactive = true;
  }

  if (inputObject) {
    // console.log(inputObject);
    inputObject.rendNode.style.cursor = "pointer";
    inputObject.rendNode.style.pointerEvents = "all";
  }
}
